import './../assets/scss/style.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from '../store/store';

import SignIn from "../pages/auth/signIn";
import SignUp from "../pages/customer/signUp";
import DjApplication from "../pages/customer/djApplication"
import RecoverPassword from "../pages/customer/recoverPassword";
import ResetPassword from '../pages/customer/resetPassword';
import Home from "../pages/home/home";
import Mixes from "../pages/outerPages/mixes";
import Career from "../pages/outerPages/career/listing";
import JobDetail from "../pages/outerPages/career/viewJob";
import ApplyJob from "../pages/outerPages/career/apply";
import MusicPlayer from '../components/musicPlayer/musicPlayer';
import NewRelease from '../pages/newrelease/newrelease';
import ArtistSongs from '../pages/artistSongs/artistSongs';
import CuratedSpotlightInedx from '../pages/curatedSpotlights/index';
import CuratedSpotlightDetail from '../pages/curatedSpotlights/detail';
import CuratedSpotlightGenre from '../pages/curatedSpotlights/genres';
import GenresIndex from '../pages/genres/index'
import ExpressDownloads from '../pages/genres/expressDownloads'
import GenreView from '../pages/genres/view';
import FavouriteSongs from '../pages/myLibrary/favourites';
import RecentlyPlayed from '../pages/myLibrary/recentlyPlayed';
import DownloadRescue from '../pages/myLibrary/downloadRescue';
import CrateIndex from '../pages/crate/crate';
import Charts from '../pages/charts/charts';
import Profile from '../pages/account/editProfile';
import ManageDevice from '../pages/account/manageDevice';
import ChangePassword from '../pages/account/changePassword';
import HelpdeskIndex from '../pages/helpdesk/home';
import HelpdeskTickets from '../pages/helpdesk/tickets';
import ViewTicket from '../pages/helpdesk/viewTicket';
import HelpdeskFAQS from '../pages/helpdesk/faqs';
import BillingHistory from '../pages/subscriptions/billingHistory';
import SubscriptionIndex from '../pages/subscriptions/home';
import PaymentMethod from '../pages/subscriptions/paymentMethod';
import PaymentMethod1 from '../pages/subscriptions/paymentMethod1';
import Logout from '../pages/account/logout';
import PlaylistIndex from '../pages/playlists/home';
import PlaylistDetail from '../pages/playlists/detail';
import ConfirmPayment from '../pages/subscriptions/confirmPayment';
import SuccessPayment from '../pages/subscriptions/successPayment';
import FailedPayment from '../pages/subscriptions/failedPayment';
import MobileWallet from '../pages/subscriptions/mobileWallet';
import UploadsList from '../pages/account/uploads';
import AddSong from '../pages/account/uploadSong';
import EditSong from '../pages/account/uploadEditSong';
import Booking from '../pages/account/booking';
import SearchResultMp3 from '../pages/search/searchResult';
import Top20Tracks from '../pages/top20/tracks'
import Top20Songs from '../pages/top20/trackSongs';
import TrendingTrackAlbums from '../pages/trendingTracks/albums';
import TrendingTrackAlbumSongs from '../pages/trendingTracks/trackSongs';
import TrendingTrackCurrent from '../pages/trendingTracks/current';
import DropboxAuth from '../pages/dropbox/dropbox-auth';
import AccountSetting from '../pages/account/setting';
import SearchReport from '../pages/reports/searchReport';
import Stats from '../pages/reports/stats';
import DownloadReports from '../pages/reports/downloadReports';
import DownloadReportsDetail from '../pages/reports/downloadReportDetail';
// Make The Kut Pages
import MTKIndex from '../pages/make-the-kut/home/index';
import MTKNewArrivals from '../pages/make-the-kut/new-arrivals/newArrivals';
import MTKTop20 from '../pages/make-the-kut/top20/top20';
import UpdatePayPalPaymentCard from '../pages/subscriptions/updatePaypalPaymentCard';
import CancelSubscriptionStep1 from '../pages/subscriptions/cancelSubscriptionStep1';

import Dmca from '../pages/account/dmca';
import StripeProvider from '../pages/subscriptions/StripeProvider';
import NotFound from '../pages/404/notFound';

function App() {
  
  return (
    <StripeProvider>
    <Provider store={store}>
    
      <Router>
        <Routes>
          {/* <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/genres/index">
            <Genres />
          </Route>
          <Route path="/genres/view/:genre_id">
            <GenreView />
          </Route>
          <Route path="/search/result/">
            <SearchResultMp3 />
          </Route>
          <Route path="/search/result/:searchTerm">
            <SearchResultMp3 />
          </Route>
          <Route path="/spotlight/index">
            <Spotlight />
          </Route>
          <Route path="/spotlight/filter/:genre_id">
            <GenreSpotlight />
          </Route>
          <Route path="/top20/tracks">
            <Top20Index />
          </Route>
          <Route path="/top20/songs/:id">
            <Top20Songs />
          </Route>
          <Route path="/spotlight/view/:id">
            <SpotlightSongs />
          </Route>
          <Route path="/newRelease">
            <NewRelease />
          </Route>
          <Route path="/recentPlayed">
            <RecentPlayed />
          </Route>
          <Route path="/playlist">
            <Playlist />
          </Route>
          <Route path="/charts">
            <Charts />
          </Route>
          <Route path="/account/update">
            <Profile />
          </Route>
          <Route path="/account/manage-subscription">
            <Subscription />
          </Route>
          <Route path="/subscription/plans">
            <SubscriptionPlans />
          </Route>
          <Route path="/subscription/choose-payment/:planId">
            <PaymentMethod />
          </Route>
          <Route path="/subscription/confirm-payment/:customerplan">
            <ConfirmPayment />
          </Route>
          <Route path="/subscription/mobile-wallet/:planId">
            <MobileWallet />
          </Route>
          <Route path="/subscription/failed">
            <FailedPayment />
          </Route>
           <Route path="/subscription/success/:profileId">
            <SuccessPayment />
          </Route>
          <Route path="/uploads/index">
            <SongList />
          </Route>
          <Route path="/uploads/new">
            <AddSong />
          </Route>
          <Route path="/uploads/edit-song/:entity_id">
            <EditSong />
          </Route>
          <Route path="/account/billing">
            <Billing />
          </Route>
          <Route path="/account/change-password">
            <ChangePassword />
          </Route>
          <Route path="/crate">
            <Crate />
          </Route>
          <Route path="/favourite-songs">
            <FavouriteSongs />
          </Route>
          <Route path="/tickets/all">
            <Tickets />
          </Route>
          <Route path="/tickets/open">
            <Tickets />
          </Route>
          <Route path="/tickets/close">
            <Tickets />
          </Route>
          <Route path="/ticket/details/:ticketNumber">
            <ViewTicket />
          </Route>
          <Route path="/support/faqs">
            <Helpdesk />
          </Route>
          <Route path="/career/listing">
            <Career />
          </Route>
          <Route path="/career/apply/:careerId">
            <Apply />
          </Route>
          <Route path="/download-rescue">
            <DownloadRescue />
          </Route>
          <Route path="/mixes">
            <Mixes />
          </Route>
          <Route path="/terms-condition">
            <Mixes />
          </Route>
          <Route path="/contact-us">
            <Contact />
          </Route>
          <Route path="/bookings">
            <Bookings />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/safety-security">
            <SafetySecurity />
          </Route> */}
          {/* <Route path="/">
            <Home />
          </Route>
          <Route path="/reset-password/:email/:token">
            <Home />
          </Route> */}
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/mixes" element={<Mixes />} />
          <Route path="/career/listing" element={<Career />} />
          <Route path="/career/detail/:careerId" element={<JobDetail />} />
          <Route path="/career/apply/:careerId" element={<ApplyJob />} />
          <Route path="/sign-in" element={<SignIn />}>
          </Route>
          <Route path="/sign-up" element={<SignUp />}>
          </Route>
          <Route path="/dj-app/:email" element={<DjApplication />}>
          </Route>
          <Route path="/recover-password" element={<RecoverPassword />}>
          </Route>
          <Route path="/reset-password/:email/:token" element={<ResetPassword />}>
          </Route>
          <Route path="/mp3/new-release" element={<NewRelease />}>
          </Route>
          <Route path="/mp3/artist/:name" element={<ArtistSongs />}>
          </Route>
          <Route path="/mp3/spotlight/home" element={<CuratedSpotlightInedx />}>
          </Route>
          <Route path="/mp3/spotlight/detail/:id" element={<CuratedSpotlightDetail />}>
          </Route>
          <Route path="/mp3/spotlight/genre/:id" element={<CuratedSpotlightGenre />}>
          </Route>
          <Route path="/mp3/genres/home" element={<GenresIndex />}>
          </Route>
		      <Route path="/mp3/genres/express-downloads" element={<ExpressDownloads />}>
          </Route>
          <Route path="/mp3/genres/:id" element={<GenreView />}>
          </Route>
          <Route path="/my-library/favourite" element={<FavouriteSongs />}>
          </Route>
          <Route path="/my-library/recently-played" element={<RecentlyPlayed />}>
          </Route>
          <Route path="/my-library/download-rescue" element={<DownloadRescue />}>
          </Route>
          <Route path="/mp3/crates" element={<CrateIndex />}>
          </Route>
          <Route path="/mp3/charts" element={<Charts />}>
          </Route>
          <Route path="/profile/update" element={<Profile />}>
          </Route>
          <Route path="/booking" element={<Booking />}>
          </Route>
          <Route path="/manage-device" element={<ManageDevice />}>
          </Route>
          <Route path="/account/setting" element={<AccountSetting />}>
          </Route> 
          <Route path="/profile/change-password" element={<ChangePassword />}>
          </Route>
          <Route path="/helpdesk/home" element={<HelpdeskIndex />}>
          </Route>
          <Route path="/helpdesk/tickets/open" element={<HelpdeskTickets />}>
          </Route>
          <Route path="/helpdesk/tickets/close" element={<HelpdeskTickets />}>
          </Route>
          <Route path="/helpdesk/ticket/details/:ticketNumber" element={<ViewTicket />}>
          </Route>
          <Route path="/helpdesk/faqs" element={<HelpdeskFAQS />}>
          </Route>
          <Route path="/subscription/billing-history" element={<BillingHistory />}>
          </Route>
          <Route path="/subscription/plans" element={<SubscriptionIndex />}>
          </Route>
          <Route path="/subscription/payment-method-card/:plan_id" element={<PaymentMethod1 />}>
          </Route>
          <Route path="/subscription/payment-method/:plan_id" element={<PaymentMethod />}>
          </Route>
          <Route path="/subscription/confirm-payment/:customerplan" element={<ConfirmPayment />}>
          </Route>
          <Route path="/subscription/success/complete" element={<SuccessPayment />}>
          </Route>
          <Route path="/subscription/payment-failed" element={<FailedPayment />}>
          </Route>
          <Route path="/logout" element={<Logout />}>
          </Route>
          <Route path="/playlists/home" element={<PlaylistIndex />}>
          </Route>
          <Route path="/playlists/detail/:id" element={<PlaylistDetail />}>
          </Route>
          <Route path="/subscription/update-card" element={<UpdatePayPalPaymentCard />}>
          </Route>
          <Route path="/subscription/cancel" element={<CancelSubscriptionStep1 />}>
          </Route>
          <Route path="/subscription/in-app-payment/:plan_id" element={<MobileWallet />}>
          </Route>
          <Route path="/uploads/list" element={<UploadsList />}>
          </Route>
          <Route path="/uploads/new" element={<AddSong />}>
          </Route>
          <Route path="/uploads/edit-song/:entity_id" element={<EditSong />}>
          </Route>
          <Route path="/search/result/" element={<SearchResultMp3 />}>
          </Route>
          <Route path="/search/result/:searchTerm" element={<SearchResultMp3 />}>
          </Route>
          <Route path="/top20/tracks" element={<Top20Tracks />}>
          </Route>
          <Route path="/top20/track/:id" element={<Top20Songs />}>
          </Route>
          <Route path="/trending-tracks/current" element={<TrendingTrackCurrent />}>
          </Route>
          {/* <Route path="/trending-tracks/albums" element={<TrendingTrackAlbums />}>
          </Route> */}
          {/* <Route path="/trending-tracks/songs/:id" element={<TrendingTrackAlbumSongs />}>
          </Route> */}
          <Route path="/dropbox-auth" element={<DropboxAuth />}>
          </Route>
          <Route path="/mp3/download-reports/" element={<DownloadReports />}>
          </Route>
          <Route path="/mp3/download-report/genre/:genre_id" element={<DownloadReportsDetail />}>
          </Route>
          <Route path="/mp3/search-report/" element={<SearchReport />}>
          </Route>
          <Route path="/mp3/stats/" element={<Stats />}>
          </Route>
          <Route path="/make-the-kut/home" element={<MTKIndex />}>
          </Route>
          <Route path="/make-the-kut/latest" element={<MTKNewArrivals />}>
          </Route>
          <Route path="/make-the-kut/top20" element={<MTKTop20 />}>
          </Route>
          <Route path="/dmca" element={<Dmca/>} />
		      <Route path='*'element={<NotFound/>} />
        </Routes>
        <MusicPlayer /> 
      </Router>
      
    </Provider>
    </StripeProvider>

  );
}

export default App;
